import React from 'react';
import './App.css';
import FullWidthTabs from './components/FullWidthTabs';
import Pdf from './components/Pdf';

function App() {
  return (
    <FullWidthTabs />
  );
}

export default App;
